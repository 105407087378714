import { GroupMemberDocument } from '@og-shared/types';
import { createStore } from '@stencil/store';
import { SPENDING_BY_TYPE_ID } from '../services/static-data';

const initialState: GroupState = {
  groupDoc: {
    auto_fill: false,
    budget_view_id: SPENDING_BY_TYPE_ID,
    created_by_uid: '',
    fill_day: 0,
    goal: null,
    group_id: null,
    invited_by_uid: null,
    name: null,
    one_goal_id: null,
    partner_id: null,
    subscription: null,
    dashboard: [],
    entitlements: {
      plaid: false,
      cash_flow: false,
      transactions: false,
      max_item_count: 7,
    },
    expires: null,
    paid_via: null,
    status: null,
    js_version: null,
    last_activity: null,
    unread_messages: 0,
    flagged_for_deletion_on: null,
    suggestion_id: null,
    analyze_spending: null,
    onboarding: {
      one_goal_set: false,
      invite_members: false,
      categorize: false,
    },
    groupLoaded: false,
    user_count: 0,
    budget_type: 'envelope',
  },
  lastGroupId: null,
  groupMembers: [],
};

export const {
  state: groupState,
  onChange: onGroupStoreChange,
  reset: resetGroupState,
  dispose: disposeGroupState,
  on: onGroupStore,
} = createStore<GroupState>(initialState);

export function waitForGroupLoaded() {
  return new Promise(resolve => {
    if (groupState.groupDoc.groupLoaded) {
      resolve(groupState.groupDoc.groupLoaded);
    } else {
      const unsubscribe = onGroupStoreChange('groupDoc', groupDoc => {
        if (!groupDoc.groupLoaded) return;
        resolve(groupDoc.groupLoaded);
        unsubscribe();
      });
    }
  });
}

export function isGroupOwner(uid: string) {
  if (!uid) return false;
  return uid === groupState.groupDoc.created_by_uid;
}

export function setGroupMembers(members: GroupMemberDocument[]) {
  groupState.groupMembers = members;
}

export function removeGroupMemberPhoto(uid: string) {
  groupState.groupMembers = groupState.groupMembers.map(m => {
    if (m.uid === uid) {
      m.photo = null;
    }
    return m;
  });
}
